document.addEventListener("DOMContentLoaded", function () {
  const steps = document.querySelectorAll(".quiz-step");
  const quizContainer = document.querySelector(".quiz-container");
  const progressBar = document.getElementById("progress-bar");
  const questionCounter = document.querySelector(".question-counter");
  const backButton = createBackButton();
  const nextButton = document.querySelector(".next-step");
  const buttonsNextAndBack = document.querySelector(".buttons-next-back");
  const quizHeader = document.querySelector(".quiz-header");
  const quizStepInfo = document.querySelector(".quiz-step-info");

  let quizAnswers = {};

  function createBackButton() {
    const backButton = document.createElement("button");
    backButton.classList.add("back-step");
    backButton.textContent = "Назад";
    backButton.style.display = "none";
    backButton.addEventListener("click", () =>
      navigateToStep(
        parseInt(quizContainer.getAttribute("data-current-step")) - 1,
      ),
    );
    document.querySelector(".buttons-next-back").prepend(backButton);
    return backButton;
  }

  function navigateToStep(index) {
    quizContainer.setAttribute("data-current-step", index);
    updateUIForStep(index);
  }

  function updateUIForStep(index) {
    steps.forEach((step) => step.classList.add("hidden"));
    steps[index].classList.remove("hidden");

    const progressPercentage = ((index + 1) / (steps.length - 1)) * 100;
    progressBar.style.width = `${progressPercentage}%`;
    questionCounter.textContent = `Вопрос ${index + 1} из ${steps.length - 2}`;

    switch (index) {
      case 0:
        backButton.style.display = "none";
        nextButton.style.display = "flex";
        break;
      case 1:
      case 2:
      case 3:
      case 4:
        backButton.style.display = "inline-flex";
        nextButton.style.display = "flex";
        break;
      case 5:
        buttonsNextAndBack.style.display = "none";
        questionCounter.textContent = `Почти готово!`;
        break;
      case 6:
        buttonsNextAndBack.style.display = "none";
        quizHeader.style.display = "none";
        quizStepInfo.style.display = "none";
        quizContainer.style.maxWidth = "100%";
        handleFinalStep();
        break;
      default:
        backButton.style.display = "inline-flex";
        nextButton.style.display = "flex";
    }

    handleStepLogic(index);
  }

  function handleStepLogic(index) {
    const step = steps[index];
    const options = step.querySelectorAll(".option");
    const customInput = step.querySelector('input[type="text"]');
    const customTextArea = step.querySelector("textarea");

    options.forEach((option) => {
      option.removeEventListener("click", handleOptionClick);
      option.addEventListener("click", handleOptionClick);
    });

    if (customInput) {
      customInput.removeEventListener("input", handleCustomInput);
      customInput.addEventListener("input", handleCustomInput);
      console.log("custom input click");
    }
    if (customTextArea) {
      customTextArea.removeEventListener("input", handleCustomTextArea);
      customTextArea.addEventListener("input", handleCustomTextArea);
      console.log("custom textarea click");
    }

    if (index === steps.length - 3) {
      handleFormStep();
    } else {
      nextButton.disabled =
        !isOptionSelected(step) && !isInputFilled(customInput);
    }
  }

  function handleOptionClick() {
    const currentStepIndex = parseInt(
      quizContainer.getAttribute("data-current-step"),
    );
    const currentStep =
      steps[parseInt(quizContainer.getAttribute("data-current-step"))];
    currentStep
      .querySelectorAll(".option")
      .forEach((opt) => opt.classList.remove("active"));

    this.classList.add("active");
    if (this.querySelector("textarea")) {
      this.querySelector("textarea").removeEventListener(
        "input",
        handleCustomTextArea,
      );
      this.querySelector("textarea").addEventListener(
        "input",
        handleCustomTextArea,
      );
      console.log("custom textarea input");
      // quizAnswers[`step${currentStepIndex + 1}`] =
      //   this.querySelector("textarea").value.trim();
      // console.log("this value", this.querySelector("textarea").value.trim());
    } else if (this.querySelector("input")) {
      this.querySelector("input").removeEventListener(
        "input",
        handleCustomInput,
      );
      this.querySelector("input").addEventListener("input", handleCustomInput);
      console.log("custom input input");
      // quizAnswers[`step${currentStepIndex + 1}`] =
      //   this.querySelector("input").value.trim();
      // console.log("this value", this.querySelector("input").value.trim());
    } else {
      quizAnswers[`step${currentStepIndex + 1}`] = this.textContent.trim();
    }
    nextButton.disabled = false;
  }

  function handleCustomInput() {
    const currentStepIndex = parseInt(
      quizContainer.getAttribute("data-current-step"),
    );
    quizAnswers[`step${currentStepIndex + 1}`] = this.value.trim();
    nextButton.disabled = !this.value.trim();
    console.log(quizAnswers);
  }
  function handleCustomTextArea() {
    const currentStepIndex = parseInt(
      quizContainer.getAttribute("data-current-step"),
    );
    quizAnswers[`step${currentStepIndex + 1}`] = this.value.trim();
    nextButton.disabled = !this.value.trim();
    console.log(quizAnswers);
  }

  function isOptionSelected(step) {
    return step.querySelector(".option.active") !== null;
  }

  function isInputFilled(input) {
    return input && input.value.trim() !== "";
  }


  function initializeContactMethods(contactMethods) {
    contactMethods.forEach((method) => {
      method.addEventListener("click", (e) => {
        let target = e.target;
        if (target.classList.contains("active")) {
          quizAnswers["contact-method"] = target.textContent.trim();
          console.log(quizAnswers["contact-method"]);
        }
      });
    });
  }
  const submitButtons = document.querySelectorAll(".submit-quiz");
  submitButtons.forEach((submitButton) => {
    console.log(submitButton);
    initializeContactMethods(
      submitButton.parentElement.querySelectorAll(".contact-method"),
    );

    submitButton.addEventListener("click", (event) => {
      event.preventDefault();
      // добавление данные формы в quizAnswers
      const formInputs = document.querySelectorAll(
        ".form-with-validation input",
      );
      formInputs.forEach((input) => {
        if (input.name && input.value) {
          quizAnswers[input.name] = input.value.trim();
        }
      });

      console.log(quizAnswers);
      // Отправка данных на сервер
      fetch("submit_form.php", {
        method: "POST",
        body: JSON.stringify(quizAnswers),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error("Error:", error));

      let popupForm = document.querySelector(
        "#popupForm .form-with-validation",
      );
      let popupFormTitle = document.querySelector(
        "#popupForm .popup-content h3",
      );
      popupForm.style.display = "none";
      popupFormTitle.style.textAlign = "center";
      popupFormTitle.style.marginBottom = "0";
      popupFormTitle.textContent = "Спасибо, ваша заявка отправлена!";
      console.log("Форма отправлена");
    });
  });
  function handleFormStep() {
    submitButtons[0].addEventListener("click", (event) => {
      event.preventDefault();
      navigateToStep(steps.length - 1);
    });
  }

  function handleFinalStep() {
    console.log("Финальный шаг достигнут");
    console.log("Ответы квиза:", quizAnswers);
  }

  nextButton.addEventListener("click", () =>
    navigateToStep(
      parseInt(quizContainer.getAttribute("data-current-step")) + 1,
    ),
  );

  navigateToStep(0);
});
